import {
  createRouter,
  createWebHistory,
  createWebHashHistory
} from 'vue-router'
import IndexPage from '../views/index/index'
const routes = [
  {
    path: '/',
    component: IndexPage
  }
]

const router = createRouter({
  // 正式
  history: createWebHistory(process.env.BASE_URL),
  // 测试
  // history: createWebHashHistory(),
  routes
})

export default router
