<template>
  <div class="content">
    <!-- 顶部轮播图 -->
    <!-- :pagination="{
        clickable: true,
        renderCustom: renderCustom,
        paginationClickable: true,
        type: 'custom',
        el: '.swiper-pagination',
      }" -->
    <div v-show="!isShowAnimation" @click="showOrHiddenBrandList(false)">
      <!-- 渐变 -->
      <img
        class="gradual"
        src="https://upload.wowqu.cn/null/9e1b9a4ac0c142a0b38fd4603d512607.png"
      />
      <swiper
        ref="slideshow"
        class="content-slideshow"
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        :pagination="{
          clickable: true,
          paginationClickable: true,
          type: 'custom',
          el: '.swiper-pagination',
        }"
        :autoplay="true"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="(item, index) in swiperPicList" v-bind:key="index">
          <img class="swiper-slide-img" :src="item" />
        </swiper-slide>
        <div class="swiper-pagination">
          <span v-for="(item, index) in swiperPicList" v-bind:key="index">
            <img
              class="swiperPaginationActiveImg"
              :class="{ ml0: index == 0 }"
              v-if="activeIndex == index"
              src="https://upload.wowqu.cn/null/3aeee07c2c1e4f4ba493cf1c50117da8.png"
            />
            <span
              @click="changeSwiper(index)"
              class="swiperPaginationImg"
              :class="{ ml0: index == 0 }"
              v-else
            ></span>
          </span>
        </div>
      </swiper>
      <!-- logo -->
      <img
        class="logo"
        src="https://upload.wowqu.cn/null/b6dd018609ce492e8d3b8bfc3c34a8fa.png"
      />
      <!-- 导航栏 -->
      <nav class="">
        <img
          class="index"
          src="https://upload.wowqu.cn/null/7ee12a16628d4ae5a6f5f9c9e13ebc3d.png"
        />
        <img
          class="group"
          src="https://upload.wowqu.cn/null/2989a852e75d469bacd8313f2b41d81d.png"
        />
        <div class="brandBox" :class="{ isShowBrand: isShowBrand }">
          <img
            class="brand"
            v-if="!isShowBrand"
            @click.stop="showOrHiddenBrandList(true)"
            src="https://upload.wowqu.cn/null/4c3df89769aa4d70a4562de8becad192.png"
          />
          <div
            class="brandList"
            v-if="isShowBrand"
            @click="showOrHiddenBrandList(false)"
          >
            <img
              class="brandList-bg"
              src="https://upload.wowqu.cn/null/869e1cdf93694ab0b79c019a38e8615d.png"
            />
            <img
              class="brandList-guiyue"
              @click="skip('http://royard.cn/')"
              @mouseenter="
                showOrHideGuiyueActive(
                  'https://upload.wowqu.cn/null/5f1add4fe19b4391942afd5774768d7c.png'
                )
              "
              @mouseleave="
                showOrHideGuiyueActive(
                  'https://upload.wowqu.cn/null/81a15f663f3f48a99f16ef9bffd8e36d.png'
                )
              "
              :src="guiyuePic"
            />
            <img
              class="brandList-wowqu"
              @click="skip('https://www.wowqu.cn/#/brand1')"
              @mouseenter="
                showOrHideWowquActive(
                  'https://upload.wowqu.cn/null/6671473fa3444ca0ae21171b18bdcb81.png'
                )
              "
              @mouseleave="
                showOrHideWowquActive(
                  'https://upload.wowqu.cn/null/67cfc8ba53304c299bf9e48b3d99c80d.png'
                )
              "
              :src="wowquPic"
            />
            <img
              class="brandList-huashe"
              @click="skip('https://www.wowqu.cn/#/brand3')"
              @mouseenter="
                showOrHideHuasheActive(
                  'https://upload.wowqu.cn/null/facfa8cc7abf42579fe1d6768d0ce641.png'
                )
              "
              @mouseleave="
                showOrHideHuasheActive(
                  'https://upload.wowqu.cn/null/5878b918a9744a53ba18bfe912358fd3.png'
                )
              "
              :src="huashePic"
            />
          </div>
        </div>
        <img
          class="joinIn"
          @click="skip('https://www.wowqu.cn/#/cooperation')"
          src="https://upload.wowqu.cn/null/61863c077d3549e2b509bbd44f9bb9a2.png"
        />
        <img
          class="lookingHouse"
          @click="lookingHouseClick"
          src="https://upload.wowqu.cn/null/b508a1782cee47a690e54c323b54b3e8.png"
        />
      </nav>
      <!-- 集团介绍 -->
      <img
        class="brandIntroduce"
        src="https://upload.wowqu.cn/null/e8ad9a8d579a4a78afee287289431fef.png"
      />
      <!-- 子品牌 -->
      <img
        class="itemBrand"
        src="https://upload.wowqu.cn/null/7be7f0941e5b40e889b85ace11773d43.png"
      />
      <!-- 品牌介绍轮播图 -->
      <swiper
        ref="brandISlideshow"
        class="content-brandISlideshow"
        :modules="modules"
        :slides-per-view="1"
        :space-between="50"
        :autoplay="true"
        :pagination="{
          clickable: true,
          paginationClickable: true,
          type: 'custom',
          el: '.swiper-pagination',
        }"
        @swiper="onBrandSwiper"
        @slideChange="onBrandSwiperChange"
      >
        <swiper-slide
          v-for="(item, index) in swiperBrandPicList"
          v-bind:key="index"
        >
          <img class="swiper-slide-img" :src="item" />
        </swiper-slide>
        <div class="swiper-pagination publicSwiper-pagination">
          <span v-for="(item, index) in swiperBrandPicList" v-bind:key="index">
            <img
              class="swiperPaginationActiveImg"
              :class="{ ml0: index == 0 }"
              v-if="activeBrandIndex == index"
              src="https://upload.wowqu.cn/null/3aeee07c2c1e4f4ba493cf1c50117da8.png"
            />
            <span
              @click="changeBrandSwiper(index)"
              class="swiperPaginationImg"
              :class="{ ml0: index == 0 }"
              v-else
            ></span>
          </span>
        </div>
      </swiper>
      <!-- 标杆门店 -->
      <img
        class="shopTitle"
        src="https://upload.wowqu.cn/null/69abb8af607948499fd37464eb3fa58e.png"
      />
      <!-- 门店图片 -->
      <div class="shopImg flex-center">
        <div class="shopImg-left">
          <img
            class="shopImg-left-img"
            src="https://upload.wowqu.cn/null/ffcff284416040f9801046965c0b2bb8.png"
          />
        </div>
        <div class="shopImg-right">
          <div class="shopImg-right-item flex-center">
            <img
              class="shopImg-right-item-img"
              src="https://upload.wowqu.cn/null/0f9f27b321934a348735fc5d9fbc1a29.png"
            />
            <img
              class="shopImg-right-item-img ml32"
              src="https://upload.wowqu.cn/null/a32fa623d6a34b1793f63e2074131470.png"
            />
          </div>
          <div class="shopImg-right-item flex-center mt31">
            <img
              class="shopImg-right-item-img"
              src="https://upload.wowqu.cn/null/49d7c3c301fd40f9bbb84bdae04caa65.png"
            />
            <img
              class="shopImg-right-item-img ml32"
              src="https://upload.wowqu.cn/null/e2c67fbb3166429e9b64171a4874bdcc.png"
            />
          </div>
        </div>
      </div>
      <!-- 置顶 -->
      <img
        class="topImg"
        @click="rollTop"
        src="https://upload.wowqu.cn/null/e15abeef985d45009479d6995023a3b4.png"
      />
      <!-- 底部 -->
      <footer class="footer flex-center">
        <img
          class="footer-img"
          src="https://upload.wowqu.cn/null/8102c75a1d9a48248401565510c0a3ff.png"
        />
        <!-- 备案号 -->
        <div class="internetContentProvider flex-center">
          <p class="textWarp">地址：广州市海珠区沥滘路368号（510290）</p>
          <p class="textWarp">
            <a
              style="color: inherit"
              href="http://beian.miit.gov.cn"
              target="_blank"
              >备案号：粤ICP备18095779号</a
            >
            |
            <a
              style="color: inherit"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010502001481"
              target="_blank"
            >
              <img
                class="internetContentProvider-img"
                src="https://upload.wowqu.cn/null/b9095acae9fc49aaa3d6c176356aafbb.png"
                alt="粤公网安"
              />粤公网安备：44010502001481
            </a>
          </p>
        </div>
        <!-- <img
        class="footer-logo"
        src="https://upload.wowqu.cn/null/eee01f48013e4866a28749aa6677222f.png"
      />
      <div class="yellowLine"></div>
      <img
        class="footer-studyLogo"
        src="https://upload.wowqu.cn/null/65201b5e91094ea6a880c8185676d15c.png"
      />
      <img
        class="footer-wx"
        src="https://upload.wowqu.cn/null/1db628cf77b04271a15345604fd65688.png"
      />
      <div class="yellowLine ml62"></div>
      <div class="yellowLine ml50"></div>
      <div class="footer-contact">
        <img
          class="footer-contact-phone"
          src="https://upload.wowqu.cn/null/1ecb9b225f994e999a644e5914c37cab.png"
        />
        <img
          class="footer-contact-email"
          src="https://upload.wowqu.cn/null/4b2ebb2791734a8d87a7c9847c9243a2.png"
        />
      </div> -->
      </footer>
      <!-- 悬浮下载app -->
      <img
        class="downApp"
        :class="{ hide: isShowApp }"
        @click="showOrHiddenApp(true)"
        src="https://upload.wowqu.cn/null/0a65f33478a04eddaf9c5ad50c794056.png"
      />
      <img
        class="downAppBanner"
        :class="{ show: isShowApp }"
        src="https://upload.wowqu.cn/null/18f21450c25640c0b4f9efa3a43a94b9.png"
      />
      <!-- 关闭app -->
      <div
        class="hideApp"
        v-if="isShowApp"
        @click="showOrHiddenApp(false)"
      ></div>
    </div>
    <!-- 首页动画 -->
    <img
      class="animationPic"
      src="https://upload.wowqu.cn/null/9576006896c948148229b99420aa37b2.gif"
      v-show="isShowAnimation"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css/bundle'

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    setTimeout(() => {
      this.isShowAnimation = false;
    }, 2260);
  },
  data() {
    return {
    };
  },
  setup() {
    // 轮播配置
    let swiper = null;
    let brandSwiper = null;
    let activeIndex = ref(0);
    let activeBrandIndex = ref(0);
    let isShowApp = ref(false);
    let isShowAnimation = ref(true);
    let isShowBrand = ref(false);
    let guiyuePic = ref("https://upload.wowqu.cn/null/81a15f663f3f48a99f16ef9bffd8e36d.png");
    let wowquPic = ref("https://upload.wowqu.cn/null/81a15f663f3f48a99f16ef9bffd8e36d.png");
    let huashePic = ref("https://upload.wowqu.cn/null/5878b918a9744a53ba18bfe912358fd3.png");
    const onSwiper = (swiperCur) => {
      swiper = swiperCur;
    };
    const onSlideChange = (swiperCur) => {
      activeIndex.value = swiperCur.activeIndex;
    };
    const onBrandSwiper = (swiperCur) => {
      brandSwiper = swiperCur;
    };
    const onBrandSwiperChange = (brandSwiper) => {
      activeBrandIndex.value = brandSwiper.activeIndex;
    };
    const changeSwiper = (index) => {
      swiper.slideTo(index);
    }
    const changeBrandSwiper = (index) => {
      brandSwiper.slideTo(index);
    }
    // 轮播图列表
    const swiperPicList = ["https://upload.wowqu.cn/null/38b9ac0e71754438bca219dc8cf0ac37.png", "https://upload.wowqu.cn/null/562a3dbd26f14aa8923b2c1a944568b3.png"]
    const swiperBrandPicList = ["https://upload.wowqu.cn/null/6e20dbeb495540e9921fb95d14409a3f.png", "https://upload.wowqu.cn/null/09c38b23c4694e7fbc1a04fb3e2a26f8.png", "https://upload.wowqu.cn/null/85dd92e1edcd4bd380af638d00023530.png"]

    const rollTop = () => {
      window.scrollTo(0, 0);
    }

    const showOrHiddenApp = (res) => {
      isShowApp.value = res;
    }
    const showOrHiddenBrandList = (res) => {
      isShowBrand.value = res;
    }
    const skip = (url) => {
      window.location.href = url;
    }
    const lookingHouseClick = () => {
      let isPhone = isPhoneComputer();
      if (isPhone) {
        skip('https://www.wowqu.cn/#/mobile');
      } else {
        skip('https://www.wowqu.cn/#/apartment/list');
      }
    }
    const showOrHideGuiyueActive = (url) => {
      guiyuePic.value = url;
    }
    const showOrHideHuasheActive = (url) => {
      huashePic.value = url;
    }
    const showOrHideWowquActive = (url) => {
      wowquPic.value = url;
    }

    // 判断是否是手机端
    const isPhoneComputer = () => {
      var info = navigator.userAgent;
      var isPhone = /mobile/i.test(info);
      return isPhone;
    }

    return {
      onSwiper,
      onSlideChange,
      changeSwiper,
      onBrandSwiper,
      onBrandSwiperChange,
      changeBrandSwiper,
      showOrHiddenBrandList,
      showOrHideGuiyueActive,
      showOrHideHuasheActive,
      showOrHideWowquActive,
      activeIndex,
      activeBrandIndex,
      swiperPicList,
      swiperBrandPicList,
      isShowAnimation,
      isShowBrand,
      isShowApp,
      guiyuePic,
      wowquPic,
      huashePic,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
      // 普通事件
      rollTop,
      showOrHiddenApp,
      skip,
      lookingHouseClick,
      isPhoneComputer,
    };
  },
});
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  width: 100%;
  overflow: hidden;
  &-slideshow {
    width: 1920px;
    .swiper-slide-img {
      width: 100%;
    }
    .swiper-pagination {
      margin-bottom: 50px;
    }
  }
  .gradual {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
  }
  .logo {
    position: absolute;
    top: 39px;
    left: 210px;
    z-index: 10;
    width: 142px;
    height: 68px;
  }
  nav {
    position: absolute;
    top: 63px;
    left: 995px;
    z-index: 10;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .index {
      width: 36px;
      height: 19px;
      cursor: pointer;
      display: inline-block;
    }
    .group {
      margin-left: 73px;
      width: 90px;
      height: 19px;
      cursor: pointer;
      display: inline-block;
    }
    .brandBox {
      margin-left: 58px;
      margin-right: 46px;
      display: inline-block;
      width: 74px;
      cursor: pointer;
      .brand {
        width: 74px;
        height: 19px;
        cursor: pointer;
        display: block;
      }
      .brandList {
        width: 146px;
        cursor: pointer;
        position: absolute;
        top: -17px;
        left: 0;
        &-bg {
          width: 146px;
        }
        &-guiyue {
          width: 85px;
          position: absolute;
          top: 79px;
          left: 0;
          padding-right: 30.5px;
          padding-left: 30.5px;
          cursor: pointer;
        }
        &-huashe {
          width: 85px;
          position: absolute;
          top: 164px;
          left: 0;
          padding-right: 30.5px;
          padding-left: 30.5px;
          cursor: pointer;
        }
        &-wowqu {
          width: 85px;
          position: absolute;
          top: 121px;
          left: 0;
          padding-right: 30.5px;
          padding-left: 30.5px;
          cursor: pointer;
        }
      }
    }
    .brandBox.isShowBrand {
      margin-left: 24px;
      margin-right: 8px;
      width: 146px;
      position: relative;
    }
    .joinIn {
      margin-left: 20px;
      width: 74px;
      display: inline-block;
      height: 19px;
      cursor: pointer;
    }
    .lookingHouse {
      display: inline-block;
      margin-left: 71px;
      width: 72px;
      height: 19px;
      cursor: pointer;
    }
  }
  .brandIntroduce {
    width: 607px;
    margin: 0 auto;
    margin-top: 129px;
    display: block;
  }
  .itemBrand {
    width: 1430px;
    margin: 0 auto;
    margin-top: 91px;
    display: block;
  }
  &-brandISlideshow {
    width: 1500px;
    margin: 0 auto;
    margin-top: 121px;
    .swiper-slide-img {
      width: 100%;
    }
  }
  .shopTitle {
    width: 196px;
    margin: 0 auto;
    margin-top: 151px;
    display: block;
  }
  .shopImg {
    margin-top: 69px;
    &-left {
      width: 479px;
      margin-right: 32px;
      &-img {
        width: 100%;
      }
    }
    &-right {
      &-item {
        width: 990px;
        &-img {
          width: 479px;
          display: block;
        }
        &-img.ml32 {
          margin-left: 32px;
        }
      }
      &-item.mt31 {
        margin-top: 31px;
      }
    }
  }
  .topImg {
    width: 42px;
    margin: 0 auto;
    margin-top: 72px;
    display: block;
    cursor: pointer;
  }
  .footer {
    width: 100%;
    height: 500px;
    background: #727171;
    margin-top: 101px;
    flex-direction: column;
    &-img {
      // width: calc(100%);
      width: calc(100% - 268px);
      margin-left: 167px;
      margin-right: 156px;
      display: block;
    }
    &-logo {
      width: 167px;
      margin-left: 167px;
      display: block;
    }
    .yellowLine {
      width: 3px;
      height: 181px;
      margin-left: 48px;
      background: #e09537;
    }
    .yellowLine.ml62 {
      margin-left: 62px;
    }
    &-studyLogo {
      width: 116px;
      margin-left: 60px;
      display: block;
    }
    &-wx {
      width: 114px;
      margin-left: 70px;
      display: block;
    }
    &-contact {
      &-phone {
        width: 408px;
        display: block;
      }
      &-email {
        margin-top: 38px;
        width: 533px;
        display: block;
      }
    }
    .internetContentProvider {
      width: 100%;
      margin-top: 100px;
      flex-direction: column;
      .textWarp {
        font-weight: 400;
        font-size: 12px;
        color: #fff;
        line-height: 17px;
        margin-top: 8px;
        font-family: -apple-system, 'PingFang SC', 'Microsoft YaHei', 'SimSun',
          'Arial', sans-serif;
        font-style: normal;
      }
      &-img {
        width: 16px;
        height: 16px;
        vertical-align: top;
        margin-right: 4px;
      }
    }
  }
  .downApp {
    cursor: pointer;
    width: 155px;
    position: fixed;
    bottom: 34px;
    left: 0;
    transition: left 1.5s ease;
    z-index: 15;
  }
  .downApp.hide {
    left: -155px;
  }
  .downAppBanner {
    margin-left: -1920px;
    width: 100%;
    position: fixed;
    z-index: 15;
    bottom: 0;
    left: 0;
    transition: margin-left 1s ease;
  }
  .downAppBanner.show {
    margin-left: 0;
  }
  .hideApp {
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: 20;
    bottom: 136px;
    right: 95px;
    cursor: pointer;
  }
  .animationPic {
    cursor: pointer;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;
  }
}
</style>
